import React, { Component, Fragment, useEffect } from 'react';
import styles from './App.less';
import Modal from 'react-modal';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import TMA_LOGO from '../_imgs/Logo';
import isSuper from '../_helpers/isSuper';
import { LS, TLD } from '../consts';
import * as compareVersions from 'compare-versions';
import Image from '../_components/Image';
import * as Sentry from '@sentry/browser';
import Notifications from '../_components/Notifications/Notifications';
import Suspended from '../Auth/Suspended';
import { NavLink } from 'react-router-dom';
import NewsFeed from '../_components/NewsFeed/NewsFeed';
import NormieNav from './NormieNav';
import { subscriptionClient } from '../client';
import Permissions from '../_components/Permissions';
import OrgSwitcher from '../_components/OrgSwitcher';
import injectIntercom from '../_thirdParty/intercom';
import { useQuery } from '@apollo/client';
import { EventSubscribe, EventTypes } from '../Events';
import CollapseHorizontalIcon from '../_icons/CollapseHorizontalIcon';
import UnstyledButton from '../_components/Button/UnstyledButton';

export const ICONS = {
  DASHBOARD: (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 10.000000)">
            <path
              d="M13.6363636,20 L17.2727273,20 C17.7727273,20 18.1818182,19.5909091 18.1818182,19.0909091 L18.1818182,8.92545455"/>
            <path
              d="M1.81818182,8.92581818 L1.81818182,19.0912727 C1.81818182,19.5912727 2.22727273,20.0003636 2.72727273,20.0003636 L6.36363636,20.0003636"/>
            <polyline points="0 10.9090909 10 0 20 10.9090909"/>
            <path
              d="M6.36363636,20 L6.36363636,13.6363636 C6.36363636,13.1363636 6.77272727,12.7272727 7.27272727,12.7272727 L12.7272727,12.7272727 C13.2272727,12.7272727 13.6363636,13.1363636 13.6363636,13.6363636 L13.6363636,20"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  ATHLETES: (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-16.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 10.000000)">
            <path
              d="M20,10 C20,4.47818182 15.5227273,0 10,0 C4.47727273,0 0,4.47818182 0,10 C0,15.5236364 4.47727273,20 10,20 C15.5227273,20 20,15.5236364 20,10 Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.6568182,8.18181818 C13.6568182,10.19 12.0286364,11.8181818 10.0204545,11.8181818 C8.01227273,11.8181818 6.38409091,10.19 6.38409091,8.18181818 C6.38409091,6.17363636 8.01227273,4.54545455 10.0204545,4.54545455 C12.0286364,4.54545455 13.6568182,6.17363636 13.6568182,8.18181818 Z"/>
            <path
              d="M4.29954545,17.6322727 C4.56409091,14.9240909 7.09590909,12.7268182 10.0004545,12.7268182 C12.905,12.7268182 15.4931818,14.7922727 15.7004545,17.5377273"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  SCHEDULE: (
    <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-17.000000, -9.000000)">
          <g transform="translate(17.000000, 9.000000)">
            <path
              d="M19,19.15 C19,19.6175 18.6318182,20 18.1818182,20 L1.81818182,20 C1.36818182,20 1,19.6175 1,19.15 L1,3.85 C1,3.3825 1.36818182,3 1.81818182,3 L18.1818182,3 C18.6318182,3 19,3.3825 19,3.85 L19,19.15 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7,1 L7,4"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13,1 L13,4"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <mask id="mask-2" fill="white">
              <polygon id="path-1" points="0 20 20 20 20 0 0 0"/>
            </mask>
            <path
              d="M15,9 L15,8 L14,9 L15,9 L14,9 L15,10 L15,9 L15,10 L16,9 L15,9 L16,9 L15,8 L15,9 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
            <path
              d="M15,14 L15,13 L14,14 L15,14 L14,14 L15,15 L15,14 L15,15 L16,14 L15,14 L16,14 L15,13 L15,14 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
            <path
              d="M5,9 L5,8 L4,9 L5,9 L4,9 L5,10 L5,9 L5,10 L6,9 L5,9 L6,9 L5,8 L5,9 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
            <path
              d="M5,14 L5,13 L4,14 L5,14 L4,14 L5,15 L5,14 L5,15 L6,14 L5,14 L6,14 L5,13 L5,14 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
            <path
              d="M10,9 L10,8 L9,9 L10,9 L9,9 L10,10 L10,9 L10,10 L11,9 L10,9 L11,9 L10,8 L10,9 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
            <path
              d="M10,14 L10,13 L9,14 L10,14 L9,14 L10,15 L10,14 L10,15 L11,14 L10,14 L11,14 L10,13 L10,14 Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              mask="url(#mask-2)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  INJURY: (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 10.000000)">
            <polyline points="10.8093043 10.9090909 13.9127826 10.9090909 13.9127826 9.09090909 10.8093043 9.09090909"/>
            <polyline points="10.8093043 9.09090909 5.21713043 9.09090909 5.21713043 10.9090909 10.8093043 10.9090909"/>
            <polyline points="8.69565217 11.2988182 8.69565217 14.5451818 10.4347826 14.5451818 10.4347826 11.2988182"/>
            <polyline points="10.4347826 8.70118182 10.4347826 5.45481818 8.69565217 5.45481818 8.69565217 8.70118182"/>
            <path
              d="M18.2430435,20 L0.887391304,20 C0.397826087,20 0.000434782609,19.5845455 0.000434782609,19.0718182 L0.000434782609,0.928181818 C0.000434782609,0.415454545 0.397826087,0 0.887391304,0 L18.2430435,0 C18.7326087,0 19.1308696,0.415454545 19.1308696,0.928181818 L19.1308696,19.0718182 C19.1308696,19.5845455 18.7326087,20 18.2430435,20 Z"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  WELLNESS: (
    <svg width="21px" height="16px" viewBox="0 0 21 16" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -11.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <polyline
            points="17 21 20.3333333 21 21.1666667 17.6666667 22.8333333 23.5 25.3333333 12.6666667 27.8333333 26 30.3333333 16.8333333 32 22.6666667 32.8333333 21 35.3333333 21"/>
        </g>
      </g>
    </svg>
  ),
  CALIBRATE: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.495 4.005C13.9666 2.50439 11.9074 1.66804 9.76546 1.67788C7.62353 1.68772 5.57214 2.54297 4.05755 4.05755C2.54297 5.57214 1.68772 7.62353 1.67788 9.76546C1.66804 11.9074 2.50439 13.9666 4.005 15.495L2.25 17.25L5.375 20.375L20.375 5.375L17.25 2.25L15.495 4.005Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65669 12.8433C5.85374 12.0193 5.40769 10.9122 5.41513 9.76171C5.42257 8.61122 5.88291 7.50996 6.69645 6.69642C7.50999 5.88288 8.61125 5.42254 9.76174 5.4151C10.9122 5.40766 12.0194 5.85371 12.8434 6.65666L6.65669 12.8433Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 17.875L6.625 16.625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.375 15.375L9.125 14.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.875 12.875L11.625 11.625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 10.375L14.125 9.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.875 7.875L16.625 6.625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  VIDEO: (
    <svg width="22px" height="16px" viewBox="0 0 22 16" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -12.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 13.000000)">
            <g>
              <polyline points="13.1012174 11.1118 19.1881739 13.300175 19.1881739 4.550175 13.1012174 6.737675"/>
              <polyline points="7.82608696 0 9.56521739 3.5 13.0434783 3.5"/>
              <polyline points="13.0434783 3.5 13.0434783 14 0 14 0 3.5 9.56521739 3.5"/>
              <path d="M2.60869565,0 L7.82608696,0"/>
            </g>
            <path d="M3.47826087,10.5 L6.95652174,10.5"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  TEMPLATES: (
    <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -10.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 11.000000)">
            <polygon points="0 6 6 6 6 0 0 0"/>
            <path d="M10,1 L20,1"/>
            <path d="M10,5 L20,5"/>
            <polygon points="0 18 6 18 6 12 0 12"/>
            <path d="M10,13 L20,13"/>
            <path d="M10,17 L20,17"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  REPORTS: (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 10.000000)">
            <polyline points="19.1512727 18.1818182 1.81854545 18.1818182 1.81854545 1.51545455"/>
            <polyline points="18.1818182 16.3645455 20 18.1818182 18.1818182 20"/>
            <polyline points="0 1.81818182 1.81818182 0 3.63636364 1.81818182"/>
            <path d="M6.36363636,13.6363636 C12.3881818,13.6363636 17.2727273,8.75272727 17.2727273,2.72727273"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  ALERTS: (
    <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-18.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(19.000000, 10.000000)">
            <path
              d="M2.72745455,10.9090909 L0.0865454545,15.55 C-0.137090909,15.9981818 0.0892727273,16.3636364 0.588363636,16.3636364 L15.7756364,16.3636364 C16.2747273,16.3636364 16.5010909,15.9981818 16.2774545,15.55 L13.6365455,10.9090909"/>
            <path
              d="M10.9092727,17.2727273 C10.9092727,18.78 9.68745455,20 8.18018182,20 C6.67472727,20 5.45290909,18.78 5.45290909,17.2727273"/>
            <path
              d="M13.6365455,5.45454545 C13.6365455,2.44181818 11.1947273,0 8.18018182,0 C5.16927273,0 2.72745455,2.44181818 2.72745455,5.45454545"/>
            <path d="M2.72745455,5.45454545 L2.72745455,10.9090909"/>
            <path d="M13.6365455,5.45454545 L13.6365455,10.9090909"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  MESSAGES: (
    <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -10.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 11.000000)">
            <path d="M4.54545455,5.45454545 L15.4545455,5.45454545"/>
            <path d="M4.54545455,8.18181818 L11.8181818,8.18181818"/>
            <g>
              <polyline points="7.27272727 13.6363636 3.63636364 18.1818182 3.63636364 13.6363636"/>
              <path
                d="M3.63636364,13.6363636 L0.909090909,13.6363636 C0.408181818,13.6363636 0,13.2272727 0,12.7272727 L0,0.909090909 C0,0.409090909 0.408181818,0 0.909090909,0 L19.0909091,0 C19.5918182,0 20,0.409090909 20,0.909090909 L20,12.7272727 C20,13.2272727 19.5918182,13.6363636 19.0909091,13.6363636 L7.27272727,13.6363636"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  SETTINGS: (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -9.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 10.000000)">
            <path
              d="M12.7272727,10 C12.7272727,11.5054545 11.5072727,12.7272727 10,12.7272727 C8.49454545,12.7272727 7.27272727,11.5054545 7.27272727,10 C7.27272727,8.49363636 8.49454545,7.27272727 10,7.27272727 C11.5072727,7.27272727 12.7272727,8.49363636 12.7272727,10 Z"/>
            <path
              d="M16.4027273,9.96181818 C16.4027273,13.4763636 13.5527273,16.3254545 10.0390909,16.3254545 C6.52363636,16.3254545 3.67454545,13.4763636 3.67454545,9.96181818 C3.67454545,6.44727273 6.52363636,3.59818182 10.0390909,3.59818182 C13.5527273,3.59818182 16.4027273,6.44727273 16.4027273,9.96181818 Z"/>
            <path d="M10,0 L10,3.59818182"/>
            <path d="M10,16.3254545 L10,20"/>
            <path d="M20,10 L16.6672727,10"/>
            <path d="M3.67454545,10 L0,10"/>
            <path d="M17.2727273,2.72727273 L14.6290909,5.37090909"/>
            <path d="M5.372,14.6270909 L2.72745455,17.2725455"/>
            <path d="M17.2727273,17.2727273 L14.6290909,14.6290909"/>
            <path d="M5.37290909,5.37290909 L2.72745455,2.72745455"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  LOGOUT: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-14.000000, -8.000000)"
          stroke="#8A96A0"
          strokeWidth="2"
        >
          <g transform="translate(15.000000, 9.000000)">
            <path
              d="M20,2 C20,3.104 19.104,4 18,4 C16.895,4 16,3.104 16,2 C16,0.896 16.895,0 18,0 C19.104,0 20,0.896 20,2 Z"/>
            <polyline points="7 6 11 3 17 9 22 7"/>
            <polyline points="14 7 5 16 0 11"/>
            <polyline points="11 11.25 15 15 15 22"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  EXERCISES: (
    <svg width="22px" height="14px" viewBox="0 0 22 14">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-16.000000, -13.000000)"
          stroke="#8A96A0"
          strokeWidth="2"
        >
          <g transform="translate(17.000000, 14.000000)">
            <polygon points="2.72727273 12 5.45454545 12 5.45454545 0 2.72727273 0"/>
            <polygon points="0 10 2.72727273 10 2.72727273 2 0 2"/>
            <polygon points="14.5454545 12 17.2727273 12 17.2727273 0 14.5454545 0"/>
            <polygon points="17.2727273 10 20 10 20 2 17.2727273 2"/>
            <path d="M5.45454545,6 L14.5454545,6"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  INGREDIENTS: (
    <svg width="15px" height="22px" viewBox="0 0 15 22">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-20.000000, -9.000000)"
          stroke="#8A96A0"
          strokeWidth="2"
        >
          <g transform="translate(21.000000, 10.000000)">
            <path d="M2.72727273,8.66654545 L2.72727273,20.0001818"/>
            <path d="M10,20 L10,0 C10,0 12.7272727,3.83181818 12.7272727,12.7272727 L10,12.7272727"/>
            <path d="M5.45454545,0 L5.45454545,6.00090909"/>
            <path d="M0,0 L0,6.00090909"/>
            <path d="M2.72727273,0 L2.72727273,5.33272727"/>
            <path d="M5.39236364,5.45454545 L0.726,5.45454545"/>
            <path
              d="M5.45454545,5.90909091 C5.45454545,7.41545455 4.23181818,8.63636364 2.72727273,8.63636364 C1.21818182,8.63636364 0,7.41545455 0,5.90909091"/>
          </g>
        </g>
      </g>
    </svg>
  ),
  SCREENING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      viewBox="0 0 18 24"
    >
      <g fill="none" fillRule="evenodd" transform="translate(1)">
        <polyline
          stroke="#8A96A0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          points="12.095 3 16 3 16 23 0 23 0 3 3.906 3"
        />
        <path
          stroke="#8A96A0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 1L12 2.5C12 3.326 11.28 4 10.4 4L5.6 4C4.72 4 4 3.326 4 2.5L4 1M4 1L12 1M12.999 8L6.999 8M10.999 16L10.999 20M12.999 18L8.999 18"
        />
        <polygon fill="#8A96A0" points="1.999 9 3.999 9 3.999 7 1.999 7"/>
        <path
          stroke="#8A96A0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12.999,11 L6.999,11"
        />
        <polygon fill="#8A96A0" points="1.999 12 3.999 12 3.999 10 1.999 10"/>
      </g>
    </svg>
  ),
  FILES: (
    <svg width="24" height="20" viewBox="0 0 24 20">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#8A96A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <path d="M11 2h10c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V1c0-.55.45-1 1-1h7"/>
        <line x1="8" x2="11" y2="2"/>
        <line x1="22" y1="6" y2="6"/>
      </g>
    </svg>
  ),
  REPORTING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeWidth="1.5"
        transform="translate(1 1)"
      >
        <rect width="16" height="16" rx="3"/>
        <polygon
          strokeLinecap="round"
          strokeLinejoin="round"
          points="3.077 8.615 3.077 12.308 6.462 12.308 6.462 8.615"
        />
        <polygon
          strokeLinecap="round"
          strokeLinejoin="round"
          points="6.462 6.154 6.462 12.308 9.538 12.308 9.538 6.154"
        />
        <polygon
          strokeLinecap="round"
          strokeLinejoin="round"
          points="9.538 3.692 9.538 12.308 12.923 12.308 12.923 3.692"
        />
      </g>
    </svg>
  ),
};

export const Link = (props) => (
  <NavLink {...props} activeClassName={styles.active}/>
);

const ParentNav = ({ application }) => {
  const { data, refetch } = useQuery(
    gql`
      query GetOnboarding {
        allOnboardingResponses(condition: { approved: false }) {
          totalCount
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(
    () => EventSubscribe(EventTypes.OnboardingFormUpdated, refetch),
    [refetch]
  );

  const hasUnapprovedOnboardingForms =
    data?.allOnboardingResponses?.totalCount > 0;

  return (
    <>
      <Link to="/" exact title="Dashboard">
        {ICONS.DASHBOARD}
        <span>Dashboard</span>
      </Link>
      <Permissions can="access:athletes">
        <Link to="/athletes" title="Athletes">
          {ICONS.ATHLETES}
          <span>Athletes</span>
        </Link>
      </Permissions>
      <Permissions can="access:files">
        <Link to="/files" title="Files">
          {ICONS.FILES}
          <span>Files</span>
        </Link>
      </Permissions>
      <Permissions can="access:onboarding">
        <Link to="/onboarding" title="Onboarding">
          {hasUnapprovedOnboardingForms && (
            <i
              style={{
                position: 'absolute',
                transform: 'translateY(-12px) translateX(-17px)',
              }}
            />
          )}
          {ICONS.TEMPLATES}
          <span>Onboarding</span>
        </Link>
      </Permissions>
      {application.features['/reporting'].isEnabled && (
        <Permissions can="access:reporting">
          <Link to="/reporting" title="Reporting">
            {ICONS.REPORTING}
            <span>Reporting</span>
          </Link>
        </Permissions>
      )}
      {application.features['/comp-reports']?.isEnabled && (
        <Link to="/comp-reports" title="Comp Reports">
          {ICONS.REPORTING}
          <span>Comp Reports</span>
        </Link>
      )}
      {application.parentIsAnnualPlanEnabled && (
        <Permissions can="access:annual-plan">
          <Link to="/annual-plan" title="Annual Plan">
            {ICONS.SCHEDULE}
            <span>Annual Plan</span>
          </Link>
        </Permissions>
      )}
      {application.features?.['/nomination']?.isEnabled && (
        <Permissions can="access:nomination">
          <Link to="/nomination" title="Nomination">
            {ICONS.REPORTING}
            <span>Nomination</span>
          </Link>
        </Permissions>
      )}
      <Link to="/settings" title="Settings">
        {ICONS.SETTINGS}
        <span>Settings</span>
      </Link>
    </>
  );
};

export class App extends Component {
  static i: App = null;
  static version = process.env.VERSION;

  static clearLocalStorage () {
    Object.keys(LS).forEach((key) => {
      localStorage.removeItem(LS[key]);
    });
  }

  static logout () {
    App.i.onLogoutClick();
  }

  state = {
    nextVersion: null,
    showErrorScreen: false,
    errorData: { info: null, error: null },
    isLoggedIn: localStorage.getItem(LS.TOKEN),
    isIntercomInjected: false,

    showNewsFeed: false,
  };

  constructor () {
    super();

    Modal.setAppElement(document.getElementById('root'));
    Modal.defaultProps.closeTimeoutMS = 150;

    App.i = this;
  }

  // React
  // =========================================================================

  componentDidMount (): void {
    this.checkAppVersion();
    setInterval(this.checkAppVersion, 60000 * 5);
  }

  componentDidUpdate (prevProps) {
    const { viewer, application } = this.props;

    if (viewer && prevProps.viewer?.id !== viewer.id) {
      Sentry.configureScope((scope) => {
        scope.setUser(viewer);
      });
    }

    if (viewer && application && !this.state.isIntercomInjected) {
      injectIntercom({
        name: viewer.fullName,
        email: viewer.email,
        organisation: application.name,
        role: viewer.role,
      });
      this.setState({ isIntercomInjected: true });
    }
  }

  // Actions
  // =========================================================================

  checkAppVersion = async () => {
    const version = await fetch('/version.html', {
      cache: 'no-store',
    }).then((res) => res.json());

    if (compareVersions(App.version, version) >= 0) return;

    this.setState({ nextVersion: version });
  };

  setLoggedIn = (isLoggedIn = true, then = null) => {
    if (isLoggedIn) {
      // Re-connect to the web socket with the auth token
      subscriptionClient.close();
      subscriptionClient.connect();
      this.props.refetchInitialMeta({
        skip: false,
      });
    }
    this.setState({ isLoggedIn }, then);
  };

  // Events
  // =========================================================================

  onLogoutClick = (e) => {
    e && e.preventDefault();
    this.setLoggedIn(false, () => {
      App.clearLocalStorage();
      subscriptionClient.close();
      window.location = window.location.origin + '/login';
    });
  };

  // Render
  // =========================================================================

  render () {
    if (
      this.props.application &&
      !this.props.application.active &&
      process.env.NODE_ENV !== 'development'
    )
      return <Suspended/>;

    const path = this.props.history.location.pathname.split('/')[1];

    return this.state.isLoggedIn && path !== 'share'
      ? this._renderAuthenticated()
      : this._renderNotAuthenticated();
  }

  _renderNotAuthenticated () {
    return typeof this.props.children === 'function'
      ? this.props.children(false)
      : this.props.children;
  }

  _renderAuthenticated () {
    const { children, application, viewerIsParentAdmin, viewerIsSuper, viewer } =
        this.props,
      { nextVersion, isMenuCollapsed } = this.state;

    const _isSuper = isSuper();
    const isParent = application?.isParent;

    let logo = TMA_LOGO;

    if (!_isSuper && application && application.assetByLogo !== null) {
      const { x1, x2 } = application.assetByLogo;

      logo = (
        <Image
          src={x1}
          srcSet={`${x1} 1x, ${x2} 2x`}
          alt={application.name}
          width={100}
          height={100}
        />
      );
    }

    const cls = [styles.sidebar];

    if (_isSuper) cls.push(styles.super);

    return (
      <>
        <Notifications/>

        <aside
          className={cls.join(' ')}
          onClick={this.onRequestCloseNotifications}
          data-cy="app-sidebar"
        >
          <div className={styles.logo}>
            {(isParent ||
              ((viewerIsParentAdmin || (viewerIsSuper && !_isSuper)) &&
                application.parentId)) && <OrgSwitcher/>}
            <Link
              to="/"
              title={application ? application.name : 'TrainMyAthlete'}
            >
              {logo}
            </Link>
            {!_isSuper && <NewsFeed/>}
          </div>

          <nav className={styles.nav}>
            {_isSuper ? (
              this._renderSuperNav()
            ) : isParent ? (
              this._renderParentNav()
            ) : viewer?.isLimitedAccess ? (
              this._renderLimitedAccessNav()
            ) : (
              <NormieNav application={application}/>
            )}
          </nav>

          <UnstyledButton
            title={isMenuCollapsed ? 'Expand Menu' : 'Collapse Menu'}
            type="button"
            className={styles.collapseMenuButton}
            onClick={() => {
              document.body.classList.toggle('smallSidebar');
              this.setState({ isMenuCollapsed: !isMenuCollapsed });
            }}
          >
            <CollapseHorizontalIcon/>
            <div className={styles.text}>Collapse Menu</div>
          </UnstyledButton>

          <button
            type="button"
            className={styles.logout}
            onClick={this.onLogoutClick}
            title="Log Out"
          >
            {ICONS.LOGOUT}
            <span>Log Out</span>
          </button>

          <div className={styles.version}>
            v{App.version}
            {nextVersion !== null && (
              <button onClick={() => window.location.reload(true)}>
                Update available, click to reload!
              </button>
            )}
          </div>
        </aside>

        <main className={styles.body}>
          {typeof children === 'function'
            ? children(isParent, application)
            : children}
        </main>
      </>
    );
  }

  _renderLimitedAccessNav () {
    const tmaOrigin = localStorage.getItem('tma-origin');
    return (
      <Fragment>
        <Link to="/" exact title="Dashboard">
          {ICONS.DASHBOARD}
          <span>Dashboard</span>
        </Link>
        {tmaOrigin ? (
          <a href={`https://${tmaOrigin}${TLD}/athletes`} title="Athletes">
            {ICONS.ATHLETES}
            <span>Athletes</span>
          </a>
        ) : (
          <Link to="/athletes" title="Athletes">
            {ICONS.ATHLETES}
            <span>Athletes</span>
          </Link>
        )}
      </Fragment>
    );
  }

  _renderSuperNav () {
    return (
      <Fragment>
        <Link to="/" exact title="Dashboard">
          {ICONS.DASHBOARD}
          <span>Dashboard</span>
        </Link>
        <Link to="/customers" title="Customers">
          {ICONS.ATHLETES}
          <span>Customers</span>
        </Link>
        <Link to="/exercises" title="Exercises">
          {ICONS.EXERCISES}
          <span>Exercises</span>
        </Link>
        <Link to="/ingredients" title="Ingredients">
          {ICONS.INGREDIENTS}
          <span>Ingredients</span>
        </Link>
        <Link to="/body-parts" title="Injury Body Parts">
          {ICONS.INJURY}
          <span>Injury Body Parts</span>
        </Link>
        <Link to="/illnesses" title="Illnesses">
          {ICONS.INJURY}
          <span>Illnesses</span>
        </Link>
        <Link to="/screenings" title="Screenings">
          {ICONS.SCREENING}
          <span>Screenings</span>
        </Link>
        <Link to="/forms" title="Forms">
          {ICONS.TEMPLATES}
          <span>Forms</span>
        </Link>
        <Link to="/tags" title="Tags">
          {ICONS.SETTINGS}
          <span>Tags</span>
        </Link>
        <Link to="/taxonomies" title="Taxonomies">
          {ICONS.SETTINGS}
          <span>Taxonomies</span>
        </Link>
        <Link to="/wellness" title="Wellness">
          {ICONS.WELLNESS}
          <span>Wellness</span>
        </Link>
        <Link to="/settings" title="Settings">
          {ICONS.SETTINGS}
          <span>Settings</span>
        </Link>
      </Fragment>
    );
  }

  _renderParentNav () {
    return <ParentNav application={this.props.application}/>;
  }
}

// Data
// =========================================================================

function hasToken () {
  const token = localStorage.getItem(LS.TOKEN);
  return typeof token === 'string' && token.trim() !== '';
}

export default compose(
  // Queries
  // =========================================================================

  // Get Application
  // -------------------------------------------------------------------------

  graphql(
    gql`
      query GetViewerAndApplicationForApp($skip: Boolean!) {
        currentApplication @skip(if: $skip) {
          id
          name
          parentIsAnnualPlanEnabled
          features
          assetByLogo {
            x1: url(width: 100, height: 100, fit: IN, trim: true)
            x2: url(width: 200, height: 200, fit: IN, trim: true)
          }
          active
          isParent
          parentId
          parentFeatures
          applicationApplicationsByParentId {
            nodes {
              childId
            }
          }
        }
        viewer @skip(if: $skip) {
          id
          fullName
          email
          isLimitedAccess
        }
        viewerIsParentAdmin @skip(if: $skip)
        viewerIsSuper @skip(if: $skip)
        unreadNews: allActivities(filter: { read: { equalTo: false } })
          @skip(if: $skip) {
          totalCount
        }
      }
    `,
    {
      options: {
        variables: {
          skip: !hasToken(),
        },
      },
      props: ({
        data: {
          currentApplication,
          viewer,
          unreadNews,
          refetch,
          viewerIsParentAdmin,
          viewerIsSuper,
        },
      }) => ({
        application: currentApplication,
        viewer,
        unreadNews,
        refetchInitialMeta: refetch,
        viewerIsParentAdmin,
        viewerIsSuper,
      }),
    }
  )
)(App);
